import { useState } from 'react';
import { assemble } from '../../util/DataUtil';
import {
  renderLocations,
  renderPlatformDigitalChilds,
  renderSocial,
  renderBottomOtherText,
  renderLocationTitle,
  renderCompanyTitle,
  renderCompanyChilds,
  renderPlatformDigitalTitle,
  renderCompanyLogo,
  renderCompanyDescription,
  renderSalesTitle,
  renderChildsRightMenuContent,
  renderChildsRightMenuContent2,
  renderChildsRightMenuContent3,
  renderChildsRightMenuContent4,
  renderChildsRightMenuContent5,
  renderChildsRightMenuTitle,
  renderCopyRights,
  renderFooterBottomElements,
} from '../../util/FooterUtil';

const Footer = ({ data, globalMetrics, hideFoooter, lang, endpoint }) => {
  if (hideFoooter == 1) {
    return '';
  }

  const [footerData, setFooterData] = useState(assemble(data));
  const footerLogo = renderCompanyLogo(footerData);
  return (
    <footer>
      <div
        className={`footer-container ${['at', 'ch', 'de'].includes(lang) ? 'footer-container-de' : ''} ${lang === 'fr' ? 'footer-container-fr' : ''}`}
      >
        <div className="top">
          <div className="left">
            <div className="top-left">
              {renderLocationTitle(footerData)}
              <ul className="footer-list">{renderLocations(footerData)}</ul>
            </div>
            <div className="top-middle">
              {renderCompanyTitle(footerData)}
              <ul className="footer-list">{renderCompanyChilds(footerData)}</ul>
            </div>
            <div className="top-right">
              {renderPlatformDigitalTitle(footerData)}
              <ul className="footer-list">
                {renderPlatformDigitalChilds(footerData)}
              </ul>
            </div>
          </div>

          <div className="right">
            <img
              className="footer-logo"
              src={footerLogo}
              alt="footer-logo"
              loading="lazy"
            />
            {renderCompanyDescription(footerData)}
            <div className="footer-services">
              <div className="service">
                {renderSalesTitle(footerData)}
                <div className="icons-text">
                  {renderChildsRightMenuContent(footerData, globalMetrics)}
                </div>
                <div className="icons-text">
                  {renderChildsRightMenuContent2(footerData, globalMetrics)}
                </div>
              </div>
              <div className="service">
                {renderChildsRightMenuTitle(footerData)}
                <div className="icons-text">
                  {renderChildsRightMenuContent3(footerData, globalMetrics)}
                </div>
                <div className="icons-text">
                  {renderChildsRightMenuContent4(footerData, globalMetrics)}
                </div>
                <div className="icons-text">
                  {renderChildsRightMenuContent5(footerData, globalMetrics)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="left">
            <div className="c">
              <img src="/images/c.svg" alt="c" />
            </div>
            {renderCopyRights(footerData)}
          </div>

          <div className="middle">
            <ul>{renderFooterBottomElements(footerData, lang)}</ul>
          </div>

          <div className="right">
            {renderBottomOtherText()}
            <div className="social-networks">{renderSocial(footerData)}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
