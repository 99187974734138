import Link from 'next/link';
import { formatIdStrings, isLinkAbsolute } from './DataUtil';
import { getContentBetweenATags } from '../components/utils/htmlUtils';
import { Constants } from './Constants';

// const getImagePathForSocial = (social) => {
//     if (social === 'Linkedin'){
//         return '/images/footer-in.svg'
//     }else if (social === 'Twitter'){
//         return '/images/footer-twiter.svg'
//     }else if (social === 'Facebook'){
//         return '/images/footer-facebook.svg'
//     }else if (social === 'Instagram'){
//         return '/images/footer-instagram.svg'
//     }
// }

const getImagePathForSocial = (social) => {
  const socialImagePaths = {
    Linkedin: '/images/footer-in.svg',
    Twitter: '/images/footer-twitter.svg',
    Facebook: '/images/footer-facebook.svg',
    Instagram: '/images/footer-instagram.svg',
    WeChat: '/images/footer-wechat.svg',
  };

  return socialImagePaths[social] || '';
};

// const getImagePathForSocialHover = (social) => {
//     if (social === 'Linkedin'){
//         return '/images/footer-in-hover.svg'
//     }else if (social === 'Twitter'){
//         return '/images/footer-twitter-hover.svg'
//     }else if (social === 'Facebook'){
//         return '/images/footer-facebook-hover.svg'
//     }else if (social === 'Instagram'){
//         return '/images/footer-instagram-hover.svg'
//     }
// }

const getImagePathForSocialHover = (social) => {
  const socialImagePathsHover = {
    Linkedin: '/images/footer-in-hover.svg',
    Twitter: '/images/footer-twitter-hover.svg',
    Facebook: '/images/footer-facebook-hover.svg',
    Instagram: '/images/footer-instagram-hover.svg',
    WeChat: '/images/footer-wechat-hover.svg',
  };

  return socialImagePathsHover[social] || '';
};

// const getClassForSocial = (social) => {
//     if (social === 'Linkedin'){
//         return 'social-network-icons in'
//     }else if (social === 'Twitter'){
//         return 'social-network-icons twit'
//     }else if (social === 'Facebook'){
//         return 'social-network-icons fb'
//     }else if (social === 'Instagram'){
//         return 'social-network-icons insta'
//     }
// }

const getClassForSocial = (social) => {
  const socialClasses = {
    Linkedin: 'social-network-icons in',
    Twitter: 'social-network-icons twit',
    Facebook: 'social-network-icons fb',
    Instagram: 'social-network-icons insta',
    WeChat: 'social-network-icons wechat',
  };

  return socialClasses[social] || '';
};

// const getClassForSocialHover = (social) => {
//     if (social === 'Linkedin'){
//         return 'social-network-icons in-hover'
//     }else if (social === 'Twitter'){
//         return 'social-network-icons twit-hover'
//     }else if (social === 'Facebook'){
//         return 'social-network-icons fb-hover'
//     }else if (social === 'Instagram'){
//         return 'social-network-icons insta-hover'
//     }
// }

const getClassForSocialHover = (social) => {
  const socialClasses = {
    Linkedin: 'social-network-icons in-hover',
    Twitter: 'social-network-icons twit-hover',
    Facebook: 'social-network-icons fb-hover',
    Instagram: 'social-network-icons insta-hover',
    WeChat: 'social-network-icons wechat-hover',
  };

  return socialClasses[social] || '';
};

export const renderLocations = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.name == 'Menu') {
      for (let child of data.children[0].children) {
        index++;

        if (child.field_hide_for_this_language == 'True') {
          continue;
        }

        result.push(
          <li key={`footer-location-index-${index}`}>
            <Link href={child.link} passHref>
              <a
                id={`footer-${formatIdStrings(child?.name)}-link`}
                target={isLinkAbsolute(child?.link) ? '_blank' : '_self'}
                className="a-footer-link footer-desc-text"
              >
                {child.name}
              </a>
            </Link>
          </li>
        );
      }
    }
  }
  return result;
};

export const renderPlatformDigitalChilds = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.name == 'Menu') {
      if (data.children[2] === undefined) {
        continue;
      }

      for (let child of data.children[2].children) {
        index++;

        if (child.field_hide_for_this_language == 'True') {
          continue;
        }

        result.push(
          <li key={`platform-digital-child-index-${index}`}>
            <Link href={child.link} passHref>
              <a
                id={`footer-${formatIdStrings(child?.name)}-link`}
                target={isLinkAbsolute(child?.link) ? '_blank' : '_self'}
                className="a-footer-link footer-desc-text"
              >
                {child.name}
              </a>
            </Link>
          </li>
        );
      }
    }
  }
  return result;
};

export const renderSocial = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  for (let data of footerData) {
    if (data.name !== 'Social networks') {
      continue;
    }

    data.children.forEach((item, index) => {
      result.push(
        <div className={'icon-holder'} key={'social-key-' + index}>
          <a className={'a-footer-link'} target="_blank" href={item.link}>
            <img
              alt="footer-in-icon"
              src={getImagePathForSocial(item.name)}
              className={getClassForSocial(item.name)}
              loading="lazy"
            />
          </a>
          <a className={'a-footer-link'} target="_blank" href={item.link}>
            <img
              alt="footer-in-hover-icon"
              src={getImagePathForSocialHover(item.name)}
              className={getClassForSocialHover(item.name)}
              loading="lazy"
            />
          </a>
        </div>
      );
    });
  }

  return result;
};

export const renderLocationTitle = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.name == 'Menu') {
      index++;

      if (data.children[0].field_hide_for_this_language == 'True') {
        continue;
      }

      result.push(
        <label
          key={`footer-location-label-index-${index}`}
          className="footer-list-header"
        >
          {data.children[0].name}
        </label>
      );
    }
  }
  return result;
};

export const renderCompanyTitle = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.name == 'Menu') {
      index++;

      if (data.children[1]?.field_hide_for_this_language == 'True') {
        continue;
      }

      result.push(
        <label
          key={`footer-label-title-index-${index}`}
          className="footer-list-header"
        >
          {data.children[1]?.name}
        </label>
      );
    }
  }

  return result;
};

export const renderCompanyChilds = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.name == 'Menu') {
      if (
        data.children[1]?.children === undefined ||
        data.children[1] === undefined
      ) {
        continue;
      }

      for (let child of data.children[1]?.children) {
        index++;

        if (child.field_hide_for_this_language == 'True') {
          continue;
        }

        result.push(
          <li key={`company-childs-index-${index}`}>
            <Link href={child.link} passHref>
              <a
                id={`footer-${formatIdStrings(child?.name)}-link`}
                target={isLinkAbsolute(child?.link) ? '_blank' : '_self'}
                className="a-footer-link footer-desc-text"
              >
                {child.name}
              </a>
            </Link>
          </li>
        );
      }
    }
  }
  return result;
};

export const renderPlatformDigitalTitle = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.name == 'Menu') {
      index++;

      if (data.children[2]?.field_hide_for_this_language == 'True') {
        continue;
      }

      result.push(
        <label
          key={`platform-digital-index-${index}`}
          className="footer-list-header"
        >
          {data.children[2]?.name}
        </label>
      );
    }
  }
  return result;
};

export const renderCompanyLogo = (footerData) => {
  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data?.children[3] === undefined) {
      continue;
    }

    if (data.name == 'Menu') {
      index++;

      if (data.children[3].field_hide_for_this_language == 'True') {
        continue;
      }
      if (data?.children?.[3]?.field_footer_cloudinary_icon !== undefined) {
        return (
          Constants.cloudinaryImage +
          getContentBetweenATags(
            data?.children?.[3]?.field_footer_cloudinary_icon
          )
        );
      } else {
        return '/images/footer_logo.png';
      }
    }
  }
};

export const renderCompanyDescription = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.children[4] === undefined) {
      continue;
    }

    if (data.name == 'Menu') {
      index++;

      if (data.children[4].field_hide_for_this_language == 'True') {
        continue;
      }

      result.push(
        <p
          key={`footer-right-text-index-${index}`}
          className="footer-right-text"
        >
          {data.children[4].description}
        </p>
      );
    }
  }
  return result;
};

export const renderSalesTitle = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  let index = 1;

  for (let data of footerData) {
    if (data.children[5] === undefined) {
      continue;
    }

    if (data.name == 'Menu') {
      index++;

      if (data.children[5].field_hide_for_this_language == 'True') {
        continue;
      }

      result.push(
        <label
          key={`footer-right-label-${index}`}
          className="footer-right-label-text"
        >
          {data.children[5].name}
        </label>
      );
    }
  }
  return result;
};

export const renderChildsRightMenuContent = (footerData, globalMetrics) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  for (let i = 0; i < globalMetrics?.field_sales_telephone?.length; i++) {
    const element = globalMetrics.field_sales_telephone[i].value;

    result.push(
      <div
        key={`footer-childs-right-menu-index-${i}`}
        className="flex-align-center"
      >
        <img alt="whatsapp" className="icon-image" src="/images/whatsapp.svg" />
        <a href={`tel:${element}`} className="a-footer-link footer-other-text">
          {element}
        </a>
      </div>
    );
  }

  return result;
};

export const renderChildsRightMenuContent2 = (footerData, globalMetrics) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }
  for (let i = 0; i < globalMetrics?.field_sales_email?.length; i++) {
    const element = globalMetrics.field_sales_email[i].value;
    result.push(
      <div
        key={`footer-childs-right-menu2-index-${i}`}
        className="flex-align-center"
      >
        <div className="mail-image">
          <img alt="mail" className="mail" src="/images/mail-white-icon.svg" />
        </div>
        <a
          href={`mailto:${element}`}
          className="a-footer-link footer-other-text mail"
        >
          {element}
        </a>
      </div>
    );
  }

  return result;
};

export const renderChildsRightMenuContent3 = (footerData, globalMetrics) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  if (
    footerData?.[0]?.children?.[footerData?.[0]?.children.length - 1]
      ?.children?.[0]?.field_hide_for_this_language === 'True'
  ) {
    return null;
  }

  for (
    let i = 0;
    i < globalMetrics?.field_customer_service_telephone?.length;
    i++
  ) {
    const element = globalMetrics.field_customer_service_telephone[i].value;
    result.push(
      <div
        key={`footer-childs-right-menu3-index-${i}`}
        className="flex-align-center"
      >
        <img
          alt="whatstapp"
          className="icon-image"
          src="/images/whatsapp.svg"
        />{' '}
        <a href={`tel:${element}`} className="a-footer-link footer-other-text">
          {element}
        </a>
      </div>
    );
  }

  // for(let data of footerData){
  //     if(data.name == 'Menu'){
  //         for (let child = 0; child < data.children[6].children.length; child++) {
  //             if(data.children[6].field_hide_for_this_language == 'True'){
  //                 continue;
  //             }
  //             result.push(<a href={`tel:${data.children[6].children[child].description}`} className="footer-other-text">{data.children[6].children[child].description}</a>);
  //             break;
  //         }
  //     }
  // }
  return result;
};

export const renderChildsRightMenuContent4 = (footerData, globalMetrics) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  if (
    footerData?.[0]?.children?.[footerData?.[0]?.children.length - 1]
      ?.children?.[1]?.field_hide_for_this_language === 'True'
  ) {
    return null;
  }

  for (
    let i = 0;
    i < globalMetrics?.field_customer_service_email?.length;
    i++
  ) {
    const element = globalMetrics.field_customer_service_email[i].value;
    result.push(
      <div
        key={`footer-childs-right-menu4-index-${i}`}
        className="flex-align-center"
      >
        <div style={{ position: 'relative', width: '19px', height: '14px' }}>
          <img alt="mail" className="mail" src="/images/mail-white-icon.svg" />
        </div>
        <a
          href={`mailto:${element}`}
          className="a-footer-link footer-other-text mail"
        >
          {element}
        </a>
      </div>
    );
  }
  return result;
};

export const renderChildsRightMenuContent5 = (footerData, globalMetrics) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  if (
    footerData?.[0]?.children?.[footerData?.[0]?.children.length - 1]
      ?.children?.[2]?.field_hide_for_this_language === 'True'
  ) {
    return null;
  }

  for (let data of footerData) {
    if (data.children[6] === undefined) {
      continue;
    }
    if (data.name == 'Menu') {
      for (let child = 0; child < data.children[6].children.length; child++) {
        if (
          child == 0 ||
          child == 1 ||
          data.children[6].field_hide_for_this_language == 'True'
        ) {
          continue;
        }

        result.push(
          <>
            <img alt="user" className="icon-image" src="/images/user.svg" />
            <Link
              key={`footer-childs-right-menu-link-index-${child}`}
              href={data.children[6].children[child].link}
              passHref
            >
              <a
                id={`footer-${formatIdStrings(data.children[6].children[child].name)}-link`}
                target={
                  isLinkAbsolute(data.children[6].children[child].link)
                    ? '_blank'
                    : '_self'
                }
                className="a-footer-link footer-other-text mail"
              >
                {data.children[6].children[child].name}
              </a>
            </Link>
          </>
        );
      }
    }
  }
  return result;
};

export const renderChildsRightMenuTitle = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }
  let index = 1;
  for (let data of footerData) {
    if (data.children[6] === undefined) {
      continue;
    }
    if (data.name == 'Menu') {
      index++;
      if (data.children[6].field_hide_for_this_language == 'True') {
        continue;
      }
      result.push(
        <label
          key={`footer-childs-right-menu-label-index-${index}`}
          className="label2 footer-right-label-text"
        >
          {data.children[6].name}
        </label>
      );
    }
  }
  return result;
};

export const renderCopyRights = (footerData) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }
  let index = 1;
  for (let data of footerData) {
    if (data.name == 'Menu Bottom') {
      index++;
      if (data.children[0].field_hide_for_this_language == 'True') {
        continue;
      }
      result.push(
        <label key={`footer-element-index-${index}`}>
          {data.children[0].description}
        </label>
      );
    }
  }
  return result;
};

export const renderFooterBottomElements = (footerData, lang) => {
  let result = [];

  if (!footerData || footerData.length == 0) {
    return;
  }

  for (let data of footerData) {
    if (data.name == 'Menu Bottom') {
      for (let child = 0; child < data.children.length; child++) {
        if (
          child == 0 ||
          data.children[child].field_hide_for_this_language == 'True'
        ) {
          continue;
        }
        result.push(
          <li key={`footer-bottom-childs-index-${child}`}>
            <Link
              className={'a-footer-link'}
              href={data.children[child].link}
              passHref
            >
              <a
                id={`footer-${formatIdStrings(data?.children[child]?.name)}-link`}
                className={`${lang === 'ko' ? 'footer-korean-link' : ''}`}
                target={
                  isLinkAbsolute(data.children[child].link) ? '_blank' : '_self'
                }
              >
                {data.children[child].name}
              </a>
            </Link>
          </li>
        );
      }
    }
  }
  return result;
};

export const renderBottomOtherText = () => {
  let result = [];
  return;

  if (!footerData || footerData.length == 0) {
    return;
  }
  let index = 1;
  for (let data of footerData) {
    if (data.name == 'Menu Bottom') {
      index++;
      if (data.children[7]?.field_hide_for_this_language == 'True') {
        continue;
      }
      result.push(
        <label
          key={`footer-bottom-childs-text-index-${index}`}
          className="bottom-other-text"
        >
          {data?.children[7]?.name}
        </label>
      );
    }
  }
  return result;
};
